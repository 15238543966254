<template> 
    <div class='image-wrapper image-wrapper-3 d-flex flex-column flex-lg-row justify-space-between' >  
       <v-img 
      src='http://globalfurnitureusa.com/Content/UploadImage/category/Medium/coolectionsubimg_29.jpg' 
      :max-width='height3'
      max-height='800px'
      fill
      href='/'
      class=''>

      </v-img>
       <div class='image-side image-side-2 d-flex align-center flex-column'>
        <div class='title-div'> <p class='title-text text-h4 text-sm-h2 mt-10 '> THE GLOBAL DIFFERENCE </p>
        </div>
        <div class="content-div mt-2 mt-lg-10 ">
            <span class='text-subtitle-1 mb-5'> 
                <ul>
                    <li>Bulk ordering allows us to buy in large quantities at advantageous prices</li>
                    <li>Our experienced manufacturing staff in Asia has the expertise and relationships to procure top-quality materials at the lowest prices</li>
                    <li>Exceptional flexibility to our retail clients, including bridge pricing and mixed containers across our Asia facilities, with very low minimum order quantity requirements</li>
                    <li>Extra inventory and safety level stock available for our direct container buyers</li>
                    <li>Exclusive manufacturing relationships across Asia and South America</li>
                    <li>High level of attention to detail surrounding product development and quality control</li>
                    <li>Unsurpassed record of on-time delivery</li>
                </ul> 
            </span>
        </div>
          </div> 
    </div>   
</template>

<script> 
    export default {
        name: 'Panel2',
        computed: {
            height3 () {
                switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '100%'
                case 'sm': return '100%'
                case 'md': return '100%'
                case 'lg': return '70%'
                case 'xl': return '70%'
                }
            }
        },
    }

</script>

<style scoped> 
.image-wrapper{
    width: 90vw;
    height: 100%;
 }
  .span {
    width: 90vw;
    height: 12vh;
    border-bottom:1px solid;
    margin-bottom: 15px;
    
  }
  .span span {
    margin-bottom: 20px;
  }

  .image-wrapper-2 {
    margin:0;
    margin-top: 8vh;
  }

  .image-side-2 {
    max-width: 100%;
    min-width: 30%;
    background-color: #676664
  }

  .title-div {
    width: 90%;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .content-div {
    width: 90%;
    height: 50%;
    display: flex;

  }

  .button-div {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    margin-bottom: 6vh;
  }

  li {
      margin: 5px;
  }
  
</style>