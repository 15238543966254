<template> 
<div class='scroll' data-simplebar data-simplebar-auto-hide="false"> 
    <div class="scrolling-wrapper-flexbox mt-lg-4 mt-sm-6 mt-xs-10" >
      <v-icon class='icon mdi-48px d-flex d-sm-none'> mdi-gesture-swipe-left </v-icon>
      <ProductScrollCard v-for='item in items' :key='item.id' :imageLink='item.imageLink' :productTitle="item.productTitle" :productDescription="item.productDescription" :productLink="item.link"/>
    </div>
  </div>
</template>

<script>
import ProductScrollCard from '../components/ProductScrollCard.vue'

export default{
    name: 'HorizontalScroll',
    components: {
        ProductScrollCard,
    },
    data () {
        return {
            items: [
                {id: 1, 
                productTitle: 'POMPEI METALLIC', 
                productDescription:'A fresh new look to a classic design defines the Pompei bedroom collection. By adding ambient LED lighting with crystal and faux croc accents, we have given a timeless style a modern update.',
                imageLink: "https://images.ctfassets.net/879w18cxw877/4Prcdpl4aI2ewlLthGhsy7/e309c37c8fea707b3dedd5f4fe5005a8/POMPEI_-_GRAY.jpg",
                link: "products/pompei-king-bed-1"},
                

                {id: 2, 
                productTitle: 'CHALICE SILVER ', 
                productDescription:'Modern Glam is awash in this bejeweled classic bedroom suite. The LED-lit upholstered headboard draws you in to comfy relaxation. The generous storage drawers ride on bearing-tracks. The elegant styling is completed by bespoke hardware which is uniquely tailored to the collection.', 
                imageLink: 'https://images.ctfassets.net/879w18cxw877/1Qt0N6Wr12HI04sLTNSyg3/bf20829b2f160709c549476cc1394666/Chalice-RSC.jpg',
                link: "products/chalice-bedroom-set"},

                {id: 3, 
                productTitle: 'D4127DT+D4127DC', 
                productDescription:'Looking to make an instant style statement then this clear glass top dining table is for you! Design elements include a two toned geometrically designed base fused with a polished chrome accent and a glass top providing enough space for all your entertaining needs.', 
                imageLink: 'https://images.ctfassets.net/879w18cxw877/1LhFzdKexex22JpKgy84RN/02b4317b50dd235e2bcadcb53ab63fd0/Screen_Shot_2023-07-17_at_11.58.51_AM.png',
                link: "products/d4127-dining-set"},

                {id: 4, 
                productTitle: 'U8253 Sofa & Love', 
                productDescription:'Consider this charming reclining collection for all your decorating needs. The brilliant charcoal tone will make pairing this item with your room decor breezy and fun. Recessed armrests with nailhead trim, ultra-padded seatbacks and semi-plush cushions provide ultimate relaxation.', 
                imageLink: 'https://images.ctfassets.net/879w18cxw877/6guBdsilk0bdMJ8y75PuE/eb43a9a6fed71afecc2827ffbd05f7b7/U8253-TQ_Grey-RSC.jpg',
                link: "products/u8253-reclining-sofa"},

                {id: 5, 
                productTitle: 'PARIS CHAMPAGNE', 
                productDescription:"Old Hollywood Glam styling with all the modern day features that today's consumer has come to expect. From the eye-catching platinum metallic finish to the fancy acrylic legs and ambient LED lighting, the Paris bedroom collection is a true showstopper.", 
                imageLink: 'https://images.ctfassets.net/879w18cxw877/6ZQ2JJrbgiRsngnci8CinN/fb6d4ca606c5e35a58f3ceb7ebcc2cf0/PARIS_RSC_23.jpg',
                link: "products/paris-bedroom-set"},
            ]
        }
    }


}
</script>

<style scoped>
  .scrolling-wrapper-flexbox {
    height: 550px;
    width: 90vw;
    display: flex;
    flex-wrap: auto;
  }
  /* Create custom widths % for different screen sizes to maintain aspect ratio */
  .card {
    flex: 0 0 auto;
    height: 90%;
    width: 40%;
    margin-left: 40px;
  }

  .scroll {
    width: 90vw;
  }

  .scroll {
    position: relative;
  }
  .simplebar-scrollbar:before{
    background-color:red;
  }
  .icon {
    max-height:75%;
    
  }

</style>

