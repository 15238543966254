<template>
    <div class='main-wrapper-flex'> 
        <v-card 
            max-width="80%"
            min-width="350"
            min-height='60vh'

            class='mb-16 mt-7 d-none d-md-flex'
            elevation='10'
        >
        <div class="main-card-wrapper-flex flex-column flex-md-row pl-10">
            <div class="left-half">
            <div class="title">
            <v-card-title class='text-h5 text-md-h2'> Get In touch </v-card-title>
        </div>
        <div class='body ml-5 mr-5'> <v-card-text>
            <div class='paragraph-body text-subtitle-2 text-md-subtitle-1'> From design and engineering to manufacturing and distribution, Global lives and breathes furniture and home interiors. Our passion and commitment to excellence, innovation, and integrity starts right here. Contact us today to inquire about your project, understand how we work, and to learn more about Global!</div>
        </v-card-text>
        </div>
        </div>
        <div class="right-half">
            <div class="title-input">
            <div> <v-text-field
            class='name-field'
            label="Full Name"
            filled
            v-model='name'
          ></v-text-field></div>
          <div><v-text-field
            class='email-field'
            label="Email Address"
            filled
             v-model='email'
          ></v-text-field></div>          
        
            </div>
        <div class='body-input ml-5'> 
            <v-textarea
                label="Your Message"
                 v-model='message'
            >       </v-textarea>
           <div class='d-flex justify-center align-center text-center'>
                <v-btn 
              class="text-button card-button text-xs-caption text-lg-button button mb-6 mt-2"
              outlined
              color="black"
              @click='SendMessage()'
            >
              {{buttonMessage}}
            </v-btn>    
           </div>
        
        </div>
        </div>
        </div>
  </v-card>

     <v-card 
            max-width="80%"
            min-width="350"
            class='mb-16 mt-7 d-flex d-md-none'
            elevation='10'
        >
        <div class="main-card-wrapper-flex flex-column flex-md-row">
            <div class="left-half-mobile left-half mb-8">
            <div class="title">
            <v-card-title class='text-h4 mb-5 text-md-h2'> Get In touch </v-card-title>
        </div>
        <div class='body ml-5 mr-5'> <v-card-text>
            <div class='paragraph-body text-subtitle-1 text-md-subtitle-1'> From design and engineering to manufacturing and distribution, Global lives and breathes furniture and home interiors. Our passion and commitment to excellence, innovation, and integrity starts right here. Contact us today to inquire about your project, understand how we work, and to learn more about Global!</div>
        </v-card-text>
        </div>
        </div>
        <div class="right-half">
            <div class="title-input">
            <div> <v-text-field
            class='name-field'
            label="Full Name"
            filled
            v-model='name'
          ></v-text-field></div>
          <div><v-text-field
            class='email-field'
            label="Email Address"
            filled
            v-model='email'
          ></v-text-field></div>          
        
            </div>
        <div class='body-input ml-5'> 
            <v-textarea
                label="Your Message"
                v-model='message'
            ></v-textarea>
            <v-btn 
              class="text-button card-button text-xs-caption text-lg-button button mb-6 mt-2"
              outlined
              color="black"
              @click='SendMessage()'
            >
              {{buttonMessage}}
            </v-btn>
        
        </div>
        
        </div>
        </div>
  </v-card>




        <panel-12 class='mb-16 mt-0'></panel-12>
    </div>
</template>


<script>

import Panel12 from '../components/About/Panel12.vue'
export default {
    name: 'ContactUS',
    components: {
        Panel12
    },
    data () {
        return {
            name: '',
            email: '',
            message: '',
            buttonMessage: 'Send'
        }
    },
    created () {
        window.scrollTo(0,0)
    },
    methods: {
        async SendMessage(){
            let message = {
                name: this.name,
                email: this.email,
                message: this.message
            }

            await fetch('https://36ns4mz2ml.execute-api.us-east-1.amazonaws.com/LambdaSESContactUs',{
                method: "post",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(message)
            })
            this.clearForm()

        },
        async clearForm(){
            this.name = ''
            this.email = ''
            this.message = ''
            await this.sleep(1)
            this.buttonMessage = 'Sent!'
            await this.sleep(2000)
            this.buttonMessage = 'Send'

        },
        sleep(ms){
                return new Promise(resolve => setTimeout(resolve, ms))
          },

    }
}


</script>


<style scoped> 
    .main-wrapper-flex {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .email-card {
        width: 80%;
        min-height: 70vh;
        display:flex;
        background-color: gray;

    }

    .first-half {
        width: 50%;
        height: 100%;
    }

    .second-half {
        width: 50%;
        height: 100%;
    }

    .map-div {
        height: 50%;
        width: 100%;
    }
    .contact-info-div {
        height: 50%;
        width: 100%;
        display: flex;
    }
    .directions-div {
        height: 100%;
        width: 50%;
    }

    .hours-div {
        height: 100%;
        width: 50%;
    }

    .name-email-div {
        display: flex;
        justify-content:space-around;
    }

    .name-input {
        width: 15vw;
    }
    
    .email-input {
        width: 15vw;
    }

    .main-card-wrapper-flex{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%; 
  }

    .body {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        height: 60%;
    }
    .left-half {
        border-right: 1px solid;
        height: 80%;
        max-width: 90%;
        min-width: 60%;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-content: center;
        background-color:rgb(2, 29, 68);
        color: white;
    }

    .left-half-mobile {
        border-right: 0px;
        border-bottom: 1px solid;
    }
    .right-half {
        max-width: 90%;
        min-width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .title-input {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        overflow: hidden;
        width: 80%;
    }
    .name-field {
        width: 120%;

    }
    .email-field {
        width: 120%;

    }

    .body-input {
        width:80%;
    }
    .other-info{
        width: 91%;
        display: flex;
        flex-direction:row;
        justify-content:space-around;
    }

    .title {
        display: flex;
        justify-content: center;
    }
</style>