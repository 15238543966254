<template>
   <div>
       <div class='d-none d-lg-flex'> 
           <div class="basic-grid">
                 <div v-for='product in products' :key='product.id' class='card-wrapper'>
                    <router-link :to="'/products/' + product.productSlug" class='router-link'>
                        <v-card
                        elevation="6"
                        class='product-card' 
                        > 
                        <div class>

                            <!-- Make height be a computed variable so that the xlg screen picture height 
                            doesn't look squashed -->

                            <div :height="height">
                                <v-img
                                contain
                                :height="height"
                                width="100%"
                                :src="'http:' + product.mainImage"
                                class='image'
                                >   </v-img>
                            </div>
                            <div class='text-div'> 
                                <h2 class='mt-2 ml-2 text-h4 text-xl-h4 product-name'>{{product.name}}</h2>
                                <span class='text-bottom'>
                                     <span class='content-seperator'>
                                        <span class='price text-body ml-2 text-h6' v-if='$auth.isAuthenticated'> Price: <sup v-if='product.price > 0'>$</sup> {{product.price}}</span>
                                        <span class='price text-body ml-2 text-h6' v-if='!$auth.isAuthenticated'> Price: <sup v-if='product.price > 0'>$</sup> {{product.msrp}}</span>
                                     </span>
                                </span>
                            </div>
                        </div>
                    </v-card>
                    </router-link>
                </div>
        
             </div>
       </div>
        <div class='d-lg-none'> 
           <div class="basic-grid-mobile">
                <div v-for='product in products' :key='product.id' class='card-wrapper'>
                    <router-link :to="'/products/' + product.productSlug" class='router-link'>
                        <v-card
                            elevation="6"
                            outlined
                            height='360px'        
                            class='product-card-mobile'
                        > 
                            <div class>
                                <!-- Make height be a computed variable so that the xlg screen picture height 
                                doesn't look squashed -->
                                <v-img
                                contain
                                :height="height"
                                width="100%"
                                :src="'http:' + product.mainImage"
                                class='image'
                                min-height="220px"
                                ></v-img>
                                <div class='text-div-mobile'> 
                                    <h2 class='mt-2 ml-2 text-h4'>{{product.name}}</h2>                                    
                                    <!-- Float this value to the bottom of the card -->
                                    <span class=' price text-body ml-2 mt-6 text-h6 mb-6' v-if='$auth.isAuthenticated'> Price: <sup v-if='product.price > 0'>$</sup> {{product.price}}</span>
                                    <span class=' price text-body ml-2 mt-6 text-h6 mb-6' v-if='!$auth.isAuthenticated'> Price: <sup v-if='product.price > 0'>$</sup> {{product.msrp}}</span>

                                    
                                </div>
                            </div>
                        </v-card>
                    </router-link>    
                </div>
             </div>
       </div>
   </div>

</template>

<script>
    export default{
        name: 'ProductList',
        props: ['products'],
        computed: {
            height () {
              switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '200px'
                case 'sm': return '25vh'
                case 'md': return '25vh'
                case 'lg': return '18vh'
                case 'xl': return '25vh'
              }
            }
        },
        created () {
            this.SetPrice()
        },
        watch: {
            products: function () {
                this.SetPrice()
            }
        },
        methods: {
            SetPrice() {
                this.products.forEach(product => {
                    if (parseFloat(product.price) === 0.00 || product.price === 0) {
                        product.price = 'Please Call for Price'
                        product.msrp = 'Please Call for Price'
                    }
                })
            }
        }
    }

</script>

<style scoped>
    .basic-grid {
        display: grid;
        gap: 1rem;
        grid-template-columns:repeat(auto-fill, minmax(20vw, 1fr));
        width: 90vw;
    }
    .basic-grid-mobile {
        display: grid;
        gap: 1rem;
        grid-template-columns:repeat(auto-fill, minmax(320px, 1fr));
        width: 90vw;
    }

    .product-card { 
        margin-top: 2vh;
        margin-left: 2px;
        margin-right: 2px;
        margin-bottom: 4vh;
        
        height: clamp(34vh, 38vh, 40vh);
    }
    .product-card-mobile{ 
        margin-top: 2vh;
        margin-left: 2px;
        margin-right: 2px;
        margin-bottom: 4vh;
        height: clamp(35vh, 40vh, 55vh);
    }

    .text-div-mobile {
        max-height: 25vh;
        min-height: 20vh;
        height:clamp(20vh,22vh,25vh);
        position: relative;
        border-top: 1px solid black;

    }
    .text-div {
        height:clamp(13vh,13vh,20vh);
        /* height: 100%; */
        border-top: 1px solid black;
        display:flex;
        flex-direction:column;
        justify-content:space-between;

    }

    .content-bottom {
        margin-top: 10px;
    }
    .content-seperator {
        width: 100%;
    }
    .router-link {
        text-decoration: none;
        width: 100%;
        height: 100%;
    }
    .product-name {
        display: flex;
    }
</style>