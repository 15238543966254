<template>
  <div class='main-wrapper d-flex justify-space-between'>
       
          <div class="card-width">
            <div class="card">
            <div class='title-wrapper'> 
                <span class='text-h2 mb-4'> Thank you <br></span>
                <span class='text-h5'> For your interest in becoming a dealer with Global Funiture Florida!</span>
            </div>

            <!-- Name Input Field -->
            <vue-form class='d-none d-md-flex'> </vue-form>
            <vue-form-mobile class='d-flex d-md-none'></vue-form-mobile>
          </div>
          </div>
       
        <site-footer></site-footer>
  </div>
</template>

<script>

    import VueForm from '../components/VueForm.vue'
    import SiteFooter from '../components/SiteFooter.vue'
    import VueFormMobile from '../components/VueFormMobile'
    export default {
        components: {
            VueForm,
            SiteFooter,
            VueFormMobile,
                
        },
        data () {
            return {
                e1: 1,
                formData: {
                    name: '',
                    email: '',
                    title: ''
                },
                errors: [],
            }
        },
        methods: {
            onSubmit () {
            }
        }
        
    }
</script>

<style scoped> 
    .main-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgb(2, 29, 68);
    }
    .v-stepper {
        width: clamp(40%, 50%, 80%);
        margin-bottom: 10vh;
    }

    .name-input {
        width: clamp(20%, 30%, 40%)
    }

    .title-wrapper {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-top: 2vh;
        margin-bottom: 4vh;
        color: white;
        text-shadow: 2px 2px rgba(0,0,0,0.1);
    }

    .card-background {
      background-color: rgb(2, 29, 68);
    }

    .card-width {
      width: 80%;
    }
</style>
