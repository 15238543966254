<template>
  <div class='main-wrapper-flex d-flex flex-column align-center '>
     <carousel class='mb-8 mb-sm-10 mb-md-0'> </carousel>


     <!-- Popular Collection Span -->
    <div class='span d-flex justify-start align-end mt-xs-10 mt-md-'> 
      <span class='text-h4'> Our Popular Collections </span> 
    </div>

    <horizontal-scroll/> 
    
    <div class='image-wrapper image-wrapper-2 d-flex flex-column flex-lg-row justify-space-between' > 
      <v-img 
      src='https://images.unsplash.com/photo-1612965607446-25e1332775ae?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bHV4dXJ5JTIwbGl2aW5ncm9vbXxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80' 
      :max-width='height2'
      max-height='800px'
      fill
      href='/'>
      </v-img>
      
      <div class='image-side image-side-2 d-flex align-center flex-column grey '>
        <div class='title-div'> <p class='title-text text-h4 mt-10 '> THE FEELING OF <br> HOME STARTS IN THE BEDROOM. </p> </div>
        <br> 
        <div class="content-div mt-2 ">
          <span class='text-subtitle-1 '> Some say the heart of the home is the kitchen. At Global, we say it’s the bedroom. Indulge in opulence or dwell in reserved luxury with our wide range of bedroom solutions. </span>
        </div>
        <div class="button-div ">
            <router-link to="bedroom/Bedroom%20Sets" class='link'>
              <v-btn 
              class="text-button card-button mt-16 text-xs-caption text-lg-button button"
              outlined
              color="black link"
            >
              <span class='link'>Explore Bedroom Collections</span>
            </v-btn>
            </router-link>
          </div> 
      </div>   
    </div>

  <div class='image-wrapper image-wrapper-3 d-flex flex-column flex-lg-row justify-space-between' >  
       <div class='image-side image-side-2 d-flex align-center flex-column white '>
        <div class='title-div'> <p class='title-text text-h4 mt-10 '> OUR STORY IS ONE OF INNOVATION,
AND A STRIVE TO EXCELLENCE. </p> </div>
        <br> 
        <div class="content-div mt-2 mt-lg-10 ">
          <span class='text-subtitle-1 '> Global Furniture USA is one of the premier importers and distributors of fine home furnishings in the United States, as well as a prominent supplier on the worldwide stage. Focusing exclusively on the wholesale market, we directly import and design new collections, and work with many of the U.S. market’s top retailers, both brick and mortar and online.
Whether you’re looking for a shared or exclusive collection, being a Global customer means having an industry-leading team on your side that understands the challenges and opportunities that exist in this industry that we all share such a passion for. </span>
        </div>
        <div class="button-div ">
            <v-btn 
              class="text-button card-button mt-16 text-xs-caption text-lg-button button"
              outlined
              color="black"
              to="/aboutus"
            >
              Our Story
            </v-btn>
          </div> 
      </div>   
      
      <v-img 
      src='http://globalfurnitureusa.com/Content/product-img/U967-The-Global-Difference-1226-X-855.jpg' 
      :max-width='height3'
      max-height='800px'
      href='/'
      class='d-none d-md-flex'>

      </v-img>
      
      
    </div>
    
    
     <div class='image-wrapper image-wrapper-2 d-flex flex-column flex-lg-row justify-space-between' > 
      <v-img 
      src='https://i0.wp.com/hausbyemilymay.com/wp-content/uploads/2021/01/ACS_0479-2.jpg?w=1100&ssl=1' 
      :max-width='height2'
      max-height='800px'
      fill
      href='/'>
      </v-img>
       <div class='image-side image-side-2 d-flex align-center flex-column grey '>
        <div class='title-div'> <p class='title-text text-h4 mt-10 '> THE FEELING OF FAMILY STARTS IN THE DINING ROOM. </p> </div>
        <br> 
        <div class="content-div mt-2">
          <span class='text-subtitle-1 '> The American dining room is a place of comfort,
safety and family. Global is redefining style in the hub of your home. </span>
        </div>
        <div class="button-div ">
            <v-btn 
              class="text-button card-button mt-16 text-xs-caption text-lg-button button d-none d-lg-flex"
              outlined
              color="black"
              to='diningroom/Dining%20Room%20%20Sets'
            >
              Explore Our Dining Room Collections
            </v-btn>
            <v-btn 
              class="text-button card-button mt-10 text-xs-caption text-lg-button button d-lg-none d-flex text-center" 
              outlined
              color="black"
              to='diningroom/Dining%20Room%20%20Sets'
              
            >
              Explore Our <br> Dining Room Collections
            </v-btn>
          </div> 
      </div>
         
  </div>
  




  <mailing-list> </mailing-list>
  <site-footer></site-footer>


    
  </div>
</template>

<script>
  import HelloWorld from '../components/HelloWorld.vue'
  import HorizontalScroll from '../components/HorizontalScroll.vue'
  import Carousel from '../components/Carousel.vue'
  import MailingList from '../components/MailingList.vue'
  import SiteFooter from '../components/SiteFooter.vue'


  export default {
    name: 'Home',

    components: {
      HelloWorld,
      HorizontalScroll,
      Carousel,
      MailingList,
      SiteFooter,
    },
    computed: {
      height () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '100%'
          case 'sm': return '100%'
          case 'md': return '100%'
          case 'lg': return '70%'
          case 'xl': return '70%'
        }
      },

     height2 () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '100%'
          case 'sm': return '100%'
          case 'md': return '100%'
          case 'lg': return '75%'
          case 'xl': return '75%'
        }
     },
      height3 () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '100%'
          case 'sm': return '100%'
          case 'md': return '100%'
          case 'lg': return '60%'
          case 'xl': return '60%'
        }
     }
     },
     data () {
       return {
       }
     },
     created () {
       window.scrollTo(0,0)
     }
  }
</script>

<style scoped> 
 
 .main-wrapper-flex {
   width: 100vw;
 }
 .image-wrapper{
    width: 90vw;
    height: 100%;
 }
  .span {
    width: 90vw;
    height: 12vh;
    border-bottom:1px solid;
    margin-bottom: 15px;
    
  }

  .span span {
    margin-bottom: 20px;
  }

  .image-wrapper-2 {
    margin:0;
    margin-top: 8vh;
  }

  .image-side-2 {
    max-width: 100%;
    min-width: 25%;
  }

  .title-div {
    width: 90%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .content-div {
    width: 90%;
    height: 50%;
    display: flex;
    text-align: center;
  }

  .button-div {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    margin-bottom: 6vh 
  }

  .button {
    margin-bottom: 60px
  }
  

  .image-wrapper-3 {
    margin-top: 10vh;
  }

  .snackbar {
    width: 100%;
    height: 10vh;
  }

  .link {
    text-decoration: none;
    color: black;
    height: 100%;
  }

  

  

</style>

