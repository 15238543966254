<template>
    <div>
        <div class='main-wrapper flex-md-row flex-column '>
        <div class='left-column'>
            <div v-for='product in cartItems' :key='product.id'>
                <div class='d-flex'>
                    <v-card 
                    class="mx-auto mb-2 cart-card"
                    :width="cardWidth"
                    tile
                    elevation='0'
                    >
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-title class="text-md-h5 text-h7  mb-1"> {{product.name}}</v-list-item-title>
                                
                                <v-list-item-subtitle class='text-subtitle-1'>Product Style: <span v-if='!(!!product.varientName)'> None</span>{{product.varientName}} </v-list-item-subtitle> 
                                <v-list-item-content class='text-subtitle-1'> <span>Price: <span v-if='product.price > 0'>$</span>{{product.price}}</span> </v-list-item-content>     
                            </v-list-item-content>
                            
                            <img height='120'
                                width='175'
                                :src="'http:' + product.imageURL">
                        </v-list-item>
                        <v-card-actions>
                            <div class='qty-field'>
                                <v-text-field
                                label="Qty"
                                outlined
                                v-model='product.quantity'
                                ></v-text-field>
                            </div>
                            <div class='action-field'>
                                <v-btn
                                outlined
                                rounded
                                class='mb-6'
                                @click='removeItem(product)'
                                
                                >
                                Remove from Cart
                                </v-btn>
                            </div>
                        </v-card-actions>
                    </v-card>
                    
                </div>
                
            </div>
            <div class='text-h4' v-if='sentEmail'> Thank you for your order. Our office will contact you shortly.</div>
        </div>
        
        <div class='right-column'>
            <div class='info-spacer mb-6'>
                <h1 class='text-h4'>Order Summary</h1>
            </div>
            <p class='text-body-1  CBM info-item'> <strong> Total Order CBM:</strong> {{CBMTotal}} M<sup>3</sup> </p>
            <p class='text-body-1  Weight info-item'> <strong>Total Order Weight:</strong> {{weightTotal}} Kg</p>
            <p class='text-h6  Weight info-item'> <strong>Order Total:</strong> ${{priceTotal}}</p>
            <div class='button-div'>
                <v-btn
                                
                                
                                class='mb-6'
                                @click='GenerateInvoice()'
                                v-if='show'
                                >
                                Place Order
                                </v-btn>
            </div>
            
        </div>
        <p></p>
    </div>
    <div class=' text-subtitles-1 d-flex justify-center align-center text-center'>
            <span class='text-button' v-if='!sentEmail'>
                **Prices subject to change without notice**
            </span>
    </div>
    
    </div>

    
</template>

<script>
    import store from '../store'
    import axios from "axios"
    export default {
        
        data (){
            return {
                quantity: 1,
                cartItems: [],
                cartTotal: 0,
                name: '',
                companyName: '',
                address: '',
                email: ' ',
                sentEmail: false,
                show: true,
        
            }
        },
        async created() {
            await this.sleep(150)
            this.cartItems = this.$store.getters.getCartItems
            //
            await this.$auth.getTokenSilently();
            await this.sleep(150)
            let metaData = this.$auth.user["http://localhost:8080/cart/user_metadata"]
            this.name = metaData.name
            this.companyName = metaData.companyName
            this.address = metaData.address
            this.email = this.$auth.user.email

        },
        methods: {
            sleep(ms){
                return new Promise(resolve => setTimeout(resolve, ms))
          },
          removeItem(product){
              this.$store.commit('removeItem', product.deleteId)
              this.cartItems = this.$store.getters.getCartItems

          },
          async GenerateInvoice () {
            this.show = false
            let checkout =  {
                name: this.name,
                companyName: this.companyName,
                address: this.address,
                email: this.email,
                cbm: this.CBMTotal,
                weight: this.weightTotal,
                items: this.cartItems
            }      

            await fetch('https://029mob7rr2.execute-api.us-east-1.amazonaws.com/SendInvoice',{
                method: 'post',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(checkout)
            })
            this.sentEmail = true
          }
        },
        computed: {
            cardWidth () {
                switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '100%'
                case 'sm': return '100%'
                case 'md': return '560'
                case 'lg': return '600'
                case 'xl': return '800'
                }
            },
            CBMTotal () {
                let total = 0
                let cartTotal = 0 
                this.cartItems.forEach(item => {
                    total = parseFloat(item.cbm) * parseFloat(item.quantity)
                    cartTotal = cartTotal + total
                })
                return Math.round(cartTotal * 100) / 100
            },
            weightTotal () {
                let itemWeight = 0
                let totalWeight = 0
                let deleteItem = false
                let removeID = ''
                
                this.cartItems.forEach(item => {
                    if ((parseInt(item.quantity) === 0)){
                        console.log('here')
                        deleteItem = true
                        removeID = item.deleteId
                    }

                    itemWeight = parseFloat(item.weight) * parseFloat(item.quantity)
                    totalWeight = totalWeight + itemWeight
                })

                if(deleteItem){
                    this.$store.commit('removeItem', removeID)
                    this.cartItems = this.$store.getters.getCartItems
                }
                return totalWeight
            },
            priceTotal () {
                var totalPrice = 0


                this.cartItems.forEach(item => {
                    if(item.price === "Please Call for Price"){
                        item.price = 0
                    }
                    totalPrice =  totalPrice + (parseFloat(item.price) * parseFloat(item.quantity))
                })
                return Math.round(totalPrice * 100)/100
            }
            
        },
        watch: {
            sentEmail: function (val) {
                this.cartItems = []
                this.$store.commit('clearCart')
                this.sentEmail = false
            },
            cartItems: function (val) {
                if(this.cartItems.length <= 0){
                    this.show = false
                }
                else {
                    this.show = true
                }
            }
        }

    }

</script>
<style scoped>
    .main-wrapper {
        width: 100%;
        height: 100%;
        display:flex;
        justify-content: center;
        overflow-x: hidden;
        
    }
    .left-column {
        min-width: 50%;
        max-width: 100%;
        height: 100%;
    }
    .right-column {
        max-width:420px;
        min-width:200px;
        min-height: 260px;
        max-height: 30vh;
        background-color: rgb(231, 231, 191);
    }
    .qty-field {
        width: 80px;
        margin-right: 4vw;
    }
    .action-field {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
    .cart-card {
        border-bottom: 1px solid;
    }
    .info-spacer {
        border-bottom: 1px solid black;
        width: 345px;
        padding-bottom: 1vh;
        padding-left: 1vw;
    }

    .info-item {
        margin-left: 1vw;
        margin-top: .5vh;
    }

    .button-div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
  
</style>